import * as utils from './_utils';
import $ from 'jquery';

const defaults = {
    $element: undefined,
    selectors: {
    },
    classes: {
    }
};

export default class Gallery {
    constructor(properties = {}) {
        const members = $.extend(true, {}, defaults, properties);

        this.$element = members.$element;
        this.selectors = members.selectors;
        this.classes = members.classes;
        this.options = members.options;
    }

    init() {
        const self = this;
    }
}
