import $ from 'jquery';

const defaults = {
    $element: undefined,
    selectors: {
        moreTrigger: '.js-read-more-toggle',
        lessTrigger: '.js-read-less-toggle',
        lessContent: '.js-read-less-content',
        moreContent: '.js-read-more-content'
    },
    classes: {
        toggled: 'toggled',
        invisible: 'invisible'
    }
};

export default class ReadMoreToggle {
    constructor(properties = {}) {
        const members = $.extend(true, {}, defaults, properties);

        this.$element = members.$element;
        this.selectors = members.selectors;
        this.classes = members.classes;
        this.moreTrigger = this.$element.find(this.selectors.moreTrigger);
        this.lessTrigger = this.$element.find(this.selectors.lessTrigger);
        this.lessContent = this.$element.find(this.selectors.lessContent);
        this.moreContent = this.$element.find(this.selectors.moreContent);
    }

    init() {
        this.bind();
    }

    bind() {
        const self = this;

        this.moreTrigger.on('click', function (e) {
            e.preventDefault();
            self.readMore(e);
        });

        this.lessTrigger.on('click', function (e) {
            e.preventDefault();
            self.readLess(e);
        });
    }

    readMore() {
        this.moreTrigger.addClass(this.classes.invisible);
        this.lessTrigger.removeClass(this.classes.invisible);
        this.toggleContent();
    }
    readLess() {
        this.lessTrigger.addClass(this.classes.invisible);
        this.moreTrigger.removeClass(this.classes.invisible);
        this.toggleContent();
    }

    toggleContent() {
        this.moreContent.toggleClass(this.classes.toggled);
        this.lessContent.toggleClass(this.classes.toggled);
    }
}
