import * as utils from './_utils';
import $ from 'jquery';

const defaults = {
    $element: undefined,
    data: {
        toValue: 'data-js-to',
        subjectValue: 'data-js-subject'
    }
};

export default class GenerateHref {
    constructor(properties = {}) {
        const members = $.extend(true, {}, defaults, properties);

        this.$element = members.$element;
        this.data = members.data;
        this.options = members.options;
    }

    init() {
        const toValue = this.$element.attr(this.data.toValue);
        const subjectValue = this.$element.attr(this.data.subjectValue);
        let hrefValue = 'mailto:' + toValue + '@legalisglobal.com ';

        if (subjectValue) {
            hrefValue = 'mailto:' + toValue + '@legalisglobal.com?subject=' + subjectValue;
        }

        this.$element.attr('href', hrefValue);
    }
}
