import * as utils from './_utils';
import $ from 'jquery';

const defaults = {
    $element: undefined,
    selectors: {
        target: '.js-scrollspy-target',
        link: ' .js-scrollspy-link'
    },
    classes: {
        active: 'active'
    }
};

export default class ScrollSpy {
    constructor(properties = {}) {
        const members = $.extend(true, {}, defaults, properties);

        this.$element = members.$element;
        this.selectors = members.selectors;
        this.classes = members.classes;
        this.options = members.options;
    }

    init() {
        const self = this;

        document.addEventListener('DOMContentLoaded', function(){

            const targets = document.querySelectorAll(self.selectors.target);

            if (!targets.length) {
                return false;
            }

            const links = document.querySelectorAll(self.selectors.link);

            const makeActive = (link) => $(links[link]).addClass(self.classes.active);
            const removeActive = (link) => $(links[link]).removeClass(self.classes.active);
            const removeAllActive = () => [...Array(targets.length).keys()].forEach((link) => removeActive(link));

            links.forEach(function (link){
                link.addEventListener('click', function (event) {
                    event.preventDefault();
                    const href = link.getAttribute("data-js-target");
                    const offset = link.offsetHeight;
                    utils.scrollToTarget(href, offset + 32);
                });
            });

            const offsetTop = 0;

            let currentActive = 0;

            window.addEventListener("scroll", () => {

                const current = targets.length - [...targets].reverse().findIndex((target) => window.scrollY >= target.offsetTop - offsetTop ) - 1;

                if (current !== currentActive) {
                    removeAllActive();
                    currentActive = current;
                    makeActive(current);
                }
            });

        }, false);
    }
}
