import events from './_events';
import ScrollSpy from './_scrollspy';
import GenerateHref from "./_generate-href";
import ReadMoreToggle from "./_read-more-toggle";
import Gallery from "./_gallery";
import utils from './_utils';

const app = (window._app = {
    _events: events,
    _scrollspy: ScrollSpy,
    _generateHref: GenerateHref,
    _readMoreToggle: ReadMoreToggle,
    _gallery: Gallery,
    _utils: utils
});

export default app;
